var LazyImages = ( function () {
  window.onload = function () {

    var VP = { height: window.innerHeight, width: window.innerWidth };
    var images = getDataSrcImages();

    function getDataSrcImages() {
      return document.querySelectorAll( 'img[data-src]' );
    }

    function checkViewPortImages() {
      images = getDataSrcImages();
      if ( images.length ) {
        console.log( images );
        images.forEach( function ( img ) {
          if ( img.y <= window.scrollY + ( VP.height * 1.5 ) ) {
            img.src = img.dataset.src;
            img.removeAttribute( 'data-src' );
          }
        } );
      } else {
        console.log( 'no mas' );
        window.removeEventListener( 'scroll', checkViewPortImages );
      }
    }
    if ( images.length ) {
      checkViewPortImages();
      window.addEventListener( 'scroll', checkViewPortImages );
    }
  }
}() );